export function addModalEventHandlers() {
  document.addEventListener('click', event => {
    const target = event.target as HTMLElement;
    if (!target.matches('[data-action="openModal"]')) return;

    const id = target.dataset.target!;
    toggleModal(id);
  });

  document.addEventListener('click', event => {
    const target = event.target as HTMLElement;
    if (!target.matches('[data-action="closeModal"]')) return;

    const id = target.dataset.target!;
    toggleModal(id);
  });

  function toggleModal(id: string) {
    const modal = document.querySelector<HTMLElement>(`[data-role="modal"][data-id="${id}"]`);
    if (!modal) return;

    const isOpen = modal.classList.contains('!flex');
    modal.classList.toggle('!flex');

    const content = modal.querySelector<HTMLElement>('[data-role="modal-content"]');
    if (!isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    function handleClickOutside(event: MouseEvent) {
      if (content!.contains(event.target as HTMLElement)) return;

      modal!.classList.remove('!flex');
      document.removeEventListener('click', handleClickOutside);
    }
  }
}
