import { loadTranslations } from '@app/translations';
import { initAppConfiguration } from '@app/utils/configuration';
import DOMRender from '@app/utils/DOMRender';
import type { StoreAlertsProps } from '@features/alerts';
import type { CatalogFilterProps } from '@features/catalog/filter';
import { addProductInquiryHandlers } from '@features/catalog/inquiry/events';
import addDialogHandler from '@features/dialog/events';
import { addBtnDropdownToggleHandler } from '@features/dropdown/events';
import { addPasswordUnlockHandler } from '@features/form/button.events';
import { addModalEventHandlers } from '@features/modal/events';
import { addGdprRemoveUserDataHandler } from '@features/myprofile/events';
import type { SidebarMobileProps } from '@features/navigation/SidebarMobile';
import type { CheckoutProps } from '@features/purchasing/checkout';
import { addBasketAddProductHandler } from '@features/purchasing/events';
import type { MyBasketProps } from '@features/purchasing/my-basket';
import { addRegistrationFormHandler } from '@features/registration/events';
import type { SearchFormProps } from '@features/search/SearchForm';
import { addTabsHandlers } from '@features/tabs/events';
import { addAccordionToggleHandler } from '@store/features/accordion/events';
import { addSearchWidgetOpenHandler } from '@store/features/search/events';

function query(selector: string) {
  return document!.querySelector<HTMLElement>(selector);
}

// const queryAll = (selector: string) => document.querySelectorAll(selector);

async function InitializeAsync() {
  // Configuration
  initAppConfiguration();

  // I18n
  await loadTranslations();

  // Events
  addDialogHandler();
  addGdprRemoveUserDataHandler();
  addSearchWidgetOpenHandler();
  addRegistrationFormHandler();
  addAccordionToggleHandler();
  addBtnDropdownToggleHandler();
  addTabsHandlers();
  addModalEventHandlers();
  addBasketAddProductHandler();
  addProductInquiryHandlers();
  addPasswordUnlockHandler();

  // 3rd party widgets
  import('@features/photoswipe/events')
    .then(e => e.initializePhotoswipe())
    .catch(err => console.error(err));
  import('@features/splidejs/events')
    .then(e => e.initializeSplideJS())
    .catch(err => console.error(err));

  // Components
  const alertsElement = query('[data-react="alerts-list"]');
  if (alertsElement) {
    import('@features/alerts')
      .then(({ default: Default }) =>
        DOMRender<StoreAlertsProps>(alertsElement, props => <Default {...props} />, {
          withQueryClient: false,
        })
      )
      .catch(err => console.error(err));
  }

  const navigationMobileElement = query('[data-react="navigation-mobile"]');
  if (navigationMobileElement) {
    import('@features/navigation/SidebarMobile')
      .then(({ default: Default }) =>
        DOMRender<SidebarMobileProps>(navigationMobileElement, props => <Default {...props} />, {
          withQueryClient: true,
        })
      )
      .catch(err => console.error(err));
  }

  const searchFormElement = query('[data-react="search-form"]');
  const searchFormMobileElement = query('[data-react="search-form-mobile"]');

  if (!!searchFormElement || !!searchFormMobileElement) {
    import('@features/search/SearchForm')
      .then(({ default: Default }) => {
        searchFormElement &&
          DOMRender<SearchFormProps>(searchFormElement, props => <Default {...props} />, {
            withQueryClient: true,
          });
        searchFormMobileElement &&
          DOMRender<SearchFormProps>(searchFormMobileElement, props => <Default {...props} />, {
            withQueryClient: true,
          });
      })
      .catch(err => console.error(err));
  }

  const catalogFilterElement = query('[data-react="catalog-filter"]');
  if (catalogFilterElement) {
    import('@features/catalog/filter')
      .then(({ default: Default }) =>
        DOMRender<CatalogFilterProps>(catalogFilterElement, props => <Default {...props} />, {
          withQueryClient: true,
        })
      )
      .catch(err => console.error(err));
  }

  // Pages
  const myBasketElement = query('[data-react="my-basket"]');
  if (myBasketElement) {
    import('@features/purchasing/my-basket')
      .then(({ default: Default }) =>
        DOMRender<MyBasketProps>(myBasketElement, props => <Default {...props} />, {
          withQueryClient: true,
        })
      )
      .catch(err => console.error(err));
  }

  const checkoutElement = query('[data-react="basket-checkout"]');
  if (checkoutElement) {
    import('@features/purchasing/checkout')
      .then(({ default: Default }) =>
        DOMRender<CheckoutProps>(checkoutElement, props => <Default {...props} />, {
          withQueryClient: true,
        })
      )
      .catch(err => console.error(err));
  }
}

InitializeAsync()
  .then(() => console.debug('Scripts initialized'))
  .catch(err => console.error(err));
