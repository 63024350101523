const resolveDuration = (input: string | undefined, defaultDuration: number) => {
  if (input) {
    const parsed = parseInt(input);
    if (!isNaN(parsed)) return parsed;
  }

  return defaultDuration;
};

export function addAccordionToggleHandler() {
  document.addEventListener('click', event => {
    const target = event.target as HTMLButtonElement;
    if (!target.matches('[data-action="accordion-toggle"]')) return;

    const icon = target.querySelector('.icon');
    if (!icon) return;
    icon.classList.toggle('rotate-180');

    const accordion = document.querySelector<HTMLElement>(`[data-id="${target.dataset.target}"]`);
    if (!accordion) return;

    const duration = resolveDuration(target.dataset.duration, 300);

    const isCollapsed = accordion.clientHeight === 0;
    if (!isCollapsed) {
      accordion.classList.add('!block');
    }

    const elStyles = window.getComputedStyle(accordion);
    const elHeight = parseFloat(elStyles.getPropertyValue('height'));
    const elPaddingTop = parseFloat(elStyles.getPropertyValue('padding-top'));
    const elPaddingBottom = parseFloat(elStyles.getPropertyValue('padding-bottom'));
    const elMarginTop = parseFloat(elStyles.getPropertyValue('margin-top'));
    const elMarginBottom = parseFloat(elStyles.getPropertyValue('margin-bottom'));

    const stepHeight = elHeight / duration;
    const stepPaddingTop = elPaddingTop / duration;
    const stepPaddingBottom = elPaddingBottom / duration;
    const stepMarginTop = elMarginTop / duration;
    const stepMarginBottom = elMarginBottom / duration;

    let start: number | undefined;

    window.requestAnimationFrame(animationStep);

    function animationStep(timestamp: number) {
      if (start === undefined) start = timestamp;

      const elapsed = timestamp - start;
      if (isCollapsed) {
        accordion!.style.height = stepHeight * elapsed + 'px';
        accordion!.style.paddingTop = stepPaddingTop * elapsed + 'px';
        accordion!.style.paddingBottom = stepPaddingBottom * elapsed + 'px';
        accordion!.style.marginTop = stepMarginTop * elapsed + 'px';
        accordion!.style.marginBottom = stepMarginBottom * elapsed + 'px';
      } else {
        accordion!.style.height = elHeight - stepHeight * elapsed + 'px';
        accordion!.style.paddingTop = elPaddingTop - stepPaddingTop * elapsed + 'px';
        accordion!.style.paddingBottom = elPaddingBottom - stepPaddingBottom * elapsed + 'px';
        accordion!.style.marginTop = elMarginTop - stepMarginTop * elapsed + 'px';
        accordion!.style.marginBottom = elMarginBottom - stepMarginBottom * elapsed + 'px';
      }

      if (elapsed >= duration) {
        accordion!.style.height = '';
        accordion!.style.paddingTop = '';
        accordion!.style.paddingBottom = '';
        accordion!.style.marginTop = '';
        accordion!.style.marginBottom = '';
        accordion!.style.overflow = '';
        if (!isCollapsed) accordion?.classList.remove('!block');
      } else {
        window.requestAnimationFrame(animationStep);
      }
    }
  });
}
