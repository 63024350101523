﻿export function addGdprRemoveUserDataHandler() {
  const dialog = document.getElementById('gdpr-request-data-deletion') as HTMLDialogElement;
  const checkbox = document.getElementById('agreeToDeletePersonalData') as HTMLInputElement;
  const submitBtn = document.getElementById('submitDeletePersonalData') as HTMLButtonElement;
  if (!dialog || !checkbox || !submitBtn) return;

  dialog.addEventListener('close', event => {
    const target = event.target as HTMLDialogElement;
    if (target.returnValue === `${true}`) {
    } else {
      checkbox.checked = false;
      checkbox.dispatchEvent(new Event('change'));
    }
  });

  checkbox.addEventListener('change', event => {
    const target = event.target as HTMLInputElement;

    if (!submitBtn) return;

    submitBtn.disabled = !target.checked;
    submitBtn.value = `${target.checked}`;
  });
}
