export function addBtnDropdownToggleHandler() {
  document.addEventListener('click', event => {
    const target = event.target as HTMLElement;
    if (!target.matches('[data-toggle="dropdown"]')) return;

    const id = target.dataset.target;
    const dropdown = document.querySelector<HTMLDivElement>(
      `[data-id="${id}"][data-role="dropdown"]`
    );
    if (!dropdown) return;
    const list = dropdown.querySelector<HTMLUListElement>('ul[role="listbox"]');

    const isOpen = !dropdown.classList.contains('hidden');

    isOpen ? close() : open();

    function open() {
      if (!dropdown) return;
      dropdown.classList.remove('hidden');
      dropdown.classList.add('block');

      target.ariaExpanded = `true`;
      if (list) list.ariaExpanded = `true`;
      document.addEventListener('click', handleClickOutside);
    }

    function close() {
      if (!dropdown) return;
      dropdown.classList.add('hidden');
      dropdown.classList.remove('block');

      target.ariaExpanded = `false`;
      if (list) list.ariaExpanded = `false`;
      document.removeEventListener('click', handleClickOutside);
    }

    function handleClickOutside(event: MouseEvent) {
      if (dropdown!.contains(event.target as HTMLElement)) return;

      close();
    }
  });
}
