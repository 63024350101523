export function addPasswordUnlockHandler() {
  document.addEventListener(
    'click',
    event => {
      const target = event.target as HTMLElement;

      if (!target.matches('[data-action="unlock-password"]')) return;
      const password = document.getElementById(target.dataset.unlockFor!) as HTMLInputElement;
      if (!password) return;

      const iconLocked = target.querySelector<HTMLElement>('.locked');
      const iconUnlocked = target.querySelector<HTMLElement>('.unlocked');

      password.type = password.type === 'password' ? 'text' : 'password';
      if (password.type === 'password') {
        iconLocked?.classList.remove('hidden');
        iconUnlocked?.classList.add('hidden');
      } else {
        iconLocked?.classList.add('hidden');
        iconUnlocked?.classList.remove('hidden');
      }
    },
    false
  );
}
