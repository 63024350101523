import { emit, emitter, EmitterEvent } from '@store/events';
import ky from 'ky';

export function addBasketAddProductHandler() {
  emitter.on(EmitterEvent.BasketReload, async (widgetUrl: string) => {
    const response = await ky.get(widgetUrl);
    if (response.ok) {
      const html = await response.text();
      const widgetElement = document.getElementById('shopping-basket-widget');
      if (widgetElement) {
        widgetElement.outerHTML = html;
      }
    }
  });

  emitter.on(EmitterEvent.BasketProductChanged, async (data: any) => {
    if (Object.prototype.hasOwnProperty.call(window, 'dataLayer')) {
      const w: Window & { dataLayer: any } = window as any;
      w.dataLayer.push({ ecommerce: null });
      w.dataLayer.push(data);
    }
  });

  document.addEventListener('submit', async event => {
    const target = event.target as HTMLFormElement;
    if (!target.matches('[data-id="addToBasket"]')) return;

    event.preventDefault();

    const fd = new FormData(target);

    const response = await ky.post(target.action, {
      body: fd,
      throwHttpErrors: false,
    });

    if (response.ok) {
      const json = await response.json<any>();
      if (json.notification) emit.alert(json.notification);
      if (json.widgetUrl) emit.basket.reload(json.widgetUrl);
      if (json.analyticsEvent) emit.basket.productChanged(json.analyticsEvent);
    }
  });
}
