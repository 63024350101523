import { Fragment, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { SWRConfig } from 'swr';

export interface RendererOptions {
  strict?: boolean;
  error?: boolean;
  withQueryClient?: boolean;
}

export default function DOMRender<TProps>(
  element: HTMLElement,
  renderFn: (props: TProps) => JSX.Element,
  options?: Partial<RendererOptions>
) {
  const renderOptions = { strict: true, error: true, withQueryClient: false, ...options };
  const dataProps = element.dataset.props ?? '{}';
  const props = JSON.parse(dataProps) as TProps;

  delete element.dataset.props;
  delete element.dataset.react;

  const ToRender = renderOptions.withQueryClient ? (
    <SWRConfig>{renderFn(props)}</SWRConfig>
  ) : (
    <Fragment>{renderFn(props)}</Fragment>
  );

  const root = createRoot(element);
  root.render(<StrictMode>{ToRender}</StrictMode>);
  return root;
}
