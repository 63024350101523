import ky from 'ky';

export function addProductInquiryHandlers() {
  document.addEventListener('submit', async event => {
    const target = event.target as HTMLFormElement;
    if (!target.matches('#product-inquiry-form')) return;

    event.preventDefault();

    const fd = new FormData(target);
    const url = target.action;

    const response = await ky.post(url, {
      body: fd,
      throwHttpErrors: false,
    });

    const successElement = document.querySelector<HTMLElement>('#product-inquiry-result-success');
    const failedElement = document.querySelector<HTMLElement>('#product-inquiry-result-failed');

    target.classList.add('!hidden');
    if (response.ok) {
      successElement?.classList?.add('!flex');
    } else {
      failedElement?.classList?.add('!flex');
    }
  });
}
