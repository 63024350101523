export function addSearchWidgetOpenHandler() {
  document.addEventListener(
    'click',
    event => {
      const target = event.target as HTMLElement;
      if (!target.closest('[data-action="search-widget-open"]')) return;

      const formWrapper = document.getElementById('search-widget-form-mobile');
      if (!formWrapper) return;
      formWrapper.classList.toggle('hidden');
      formWrapper.classList.toggle('flex');
    },
    false
  );
}
